import React from "react";
import { Link } from "react-router-dom";
import EnigmaLogo from "./Components/EnigmaLogo";
import { AiFillLinkedin, AiFillGithub, AiOutlineTwitter } from "react-icons/ai";

import "./css/app.css";

export default function App() {
  return (
    <div className="home">
      <section className="home-top">
        <div className="dev-title">
          <h1>Dushyanth</h1>
          <h1 className="red">Gali</h1>
        </div>
        <div className="enigma">
          <EnigmaLogo />
        </div>
        <div className="indicator"></div>
        <div className="links">
          <a href="https://www.linkedin.com/in/g-dushyanth/" target="_blank">
            <div>
              <AiFillLinkedin size={20} />
            </div>
          </a>

          <a href="https://github.com/IamDushu" target="_blank">
            {" "}
            <div>
              {" "}
              <AiFillGithub size={20} />{" "}
            </div>
          </a>

          <a href="https://twitter.com/iam_dushu" target="_blank">
            {" "}
            <div>
              {" "}
              <AiOutlineTwitter size={20} />
            </div>
          </a>
        </div>
      </section>
      <section className="home-bottom">
        <div className="job-title">
          <h1>I'M A <br /> GOPHER</h1>
        </div>
        <div className="route">
          {/* <Link to="/about">
              <button className="btn">ABOUT</button>
            </Link> */}
          <Link to="/projects">
            <button className="btn">1's & 0's</button>
          </Link>
          <a href="https://blog.dushyanth.in" target="_blank">
            <button className="btn">BLOG</button>
          </a>
          {/*
          <Link to="/resume">
            <button className="btn">RÉSUMÉ</button>
          </Link> */}
          {/* <Link to="/education">
            <button className="btn">EDUCATION</button>
          </Link> */}
          <Link to="/contact">
            <button className="btn">CONTACT</button>
          </Link>
        </div>
      </section>
    </div>
  );
}
