import React from "react"
import EducationProfile from "../../Components/EducationProfile"
import Navbar from "../../Components/Navbar"
import NavbarMobile from "../../Components/NavbarMobile"

export default function Education() {
  return (
    <div className="page">
      <Navbar className="desktop" />
      <NavbarMobile className="mobile" />
      <div className="content">
        <h1>EDUCATION</h1>
        <hr></hr>
        <div className="eduProfileContainer">
          <EducationProfile
            date="Nov 2022 - Present"
            title="Ethereum Dev."
            subtitle="Blockchain | Solidity | Smart Contracts | Ethereum "
            school="Alchemy University"
          />
          <EducationProfile
            date="July 2018 - December 2021"
            title="Bachelor of Arts"
            subtitle="Political Science & International Relations"
            school="S V University, India"
          />
          <EducationProfile
            date="May 2015"
            title="School Certificate"
            school="S V International School, India"
          />
        </div>
      </div>
    </div>
  )
}
